import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './components/store';
import router from './router/index';
import fetchData from './utils/fetchData';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader

Vue.config.errorHandler = (err, vm, info) => {
  const currentVersion = vm.$store.getters.currentVersion;
  const server = localStorage.getItem('serverURL') || 'https://rinc.us/';
  const userData = {
    message: err.message,
    source: info,
    line: null,
    columnname: null,
    error: err.toString(),
    browser: navigator.userAgent,
    version: currentVersion,
    appversion: currentVersion,
    referrer: null,
  };
  try {
    fetchData(
      {
        center: 'user', section: 'insert', tab: 'single', kind: 'clientSideErrorReport',
      },
      null,
      userData,
      server,
    );
  } catch (e) {
    return `e${e}`;
  }
  return true;
};

Vue.use(Vuelidate);
// Vue.config.productionTip = true;

function init() {
  new Vue({
    name: 'MainPg',
    router,
    store,
    vuetify,
    render: (h) => {
      return h(App);
    },
  }).$mount('#app');
  universalLinks.subscribe('didLaunchAppFromLink', (event) => {
    const path = event.path;
    localStorage.setItem('linkPath', path);
    router.push(path);
  });
}
document.addEventListener('deviceready', init, false);
